import Vue from 'vue';
// import request from '@/api/request';
// 自动撑高度
function heightFullScreen(wrapper) {
  const tempWrap = wrapper;
  const height = window.innerHeight;
  Vue.nextTick(() => {
    const currentHeight = document.body.getBoundingClientRect().height;
    const wrapperHeight = currentHeight < height ? height - currentHeight : 500;
    tempWrap.style.cssText = `height: ${tempWrap.getBoundingClientRect()
      .height + wrapperHeight}px`;
  });
}

function isWx() {
  const ua = window.navigator.userAgent.toLowerCase();
  const result = ua.match(/micromessenger/i) || ua.match(/wechatdevtools/i);
  return Boolean(result);
}

function accept(data) {
  console.log(data);
}

function swtichTopicHint(topicid) {
  console.log(topicid);
}

export default {
  install: () => {
    Vue.prototype.heightFullScreen = (wrapper) => heightFullScreen(wrapper);
    Vue.prototype.isWx = () => isWx();
    Vue.prototype.accept = (data) => accept(data);
    Vue.prototype.swtichTopicHint = (topicid) => swtichTopicHint(topicid);
  },
};
