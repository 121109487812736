import Vue from 'vue';
import {
  Search, List, Popup, Form, Field, Button, DatetimePicker, SwipeCell, Dialog, Overlay, Notify, Loading, RadioGroup, Radio,
  Tab, Tabs, CellGroup,
  Picker,
  Checkbox,
} from 'vant';

[
  Search, List, Popup, Form, Field, Button, DatetimePicker, SwipeCell, Dialog, Overlay, Notify, Loading, RadioGroup, Radio,
  Tab, Tabs, CellGroup,
  Picker,
  Checkbox,
].forEach((item) => {
  Vue.use(item);
});
