import axios from 'axios';
import { getToken } from '@/libs/token';
import qs from 'qs';
import { Notify } from 'vant';

// const fs = require('fs');
// const path = require('path');

// const ua = navigator.userAgent.toLowerCase();
// const isInWx = ua.match(/micromessenger/i) === 'micromessenger';

const baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.baseURL = baseURL;

const service = axios.create({
  baseURL,
  timeout: 10000,
});

service.interceptors.request.use(
  (config) => {
    const tempConfig = config;
    const token = getToken();
    if (token) {
      tempConfig.headers.Authorization = `Bearer ${token}`;
    }
    tempConfig.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    if (tempConfig.method === 'post') {
      tempConfig.data = qs.stringify(tempConfig.data);
    }
    // 当前设备
    // tempConfig.headers.Dev = bowser.mobile ? 'wap' : 'web';
    // tempConfig.headers['In-Wei-xin'] = isInWx;
    return tempConfig;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.status !== 0) {
      const message = res.error instanceof Array ? res.error.join('\r\n') : res.error;
      if (res.status === 401) {
        // 去登陆
        // const host = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : process.env.VUE_APP_BASE;
        // window.location.href = `${host}login`;
      } else {
        // Message({
        //   message,
        //   type: 'error',
        //   duration: 3 * 1000,
        // });
        console.log(res.status);
        if (res.status === 500) {
          // message = '服务器开小差 请稍后再试';
          Notify({
            message,
            duration: 3 * 1000,
          });
        }
        // return Promise.reject(new Error(message) || 'Error');
        const reason = {
          message,
          status: res.status,
        };
        if (res.data) {
          reason.data = res.data;
        }
        return Promise.reject(reason || 'Error');
      }
    }
    return res;
  },
  (error) => {
    console.error(`err${error}`);
    console.log(error.config);
    if (error.message.indexOf('timeout') === -1) {
      Notify({
        message: `res:${error.message},url:${error.config.url}`,
        duration: 3 * 1000,
      });
    }
    return Promise.reject(error);
  },
);

export default service;
