var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-bottom-container"},[_c('div',{staticClass:"bottom-container__inner"},_vm._l((_vm.navs),function(nav,index){return _c('div',{key:index,staticClass:"bottom__item",class:{
        '-has-unread': _vm.unread && nav.name === '消息',
      }},[(nav.name === '消息')?[_c('div',{staticClass:"item__main-body",class:{
            'router-link-active': _vm.$route.name === 'app-notice-list',
          },on:{"click":function($event){return _vm.noticeJump(_vm.unread, nav.link)}}},[_c('i',[_c('svg-icon',{attrs:{"icon-class":nav.icon}})],1),_c('p',[_vm._v(_vm._s(nav.name))])])]:_c('router-link',{staticClass:"item__main-body",class:{
  'router-link-active': nav.name === '亲友' && _vm.currentURI === 'route-groups'
},attrs:{"to":{
          path: nav.link,
        }}},[_c('i',[_c('svg-icon',{attrs:{"icon-class":nav.icon}})],1),_c('p',[_vm._v(_vm._s(nav.name))])])],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }