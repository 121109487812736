import { mapState, mapMutations } from 'vuex';

const shareBack = {
  computed: {
    ...mapState(['forward', 'goBackCount']),
    from() {
      return this.$route.query.from;
    },
    tuid() {
      return this.$route.query.tuid;
    },
  },
  methods: {
    ...mapMutations(['setCurrentBackCount']),
    goBack(topicID = 0) {
      console.group('goback');
      console.log(this.from);
      console.log(this.$route.params.groupID);
      console.log(topicID);
      console.log(window.history.state);
      // window.history.back();
      this.setCurrentBackCount(this.goBackCount);
      if (this.from) {
        let path = this.url;
        if (path === undefined) {
          if (this.$route.params.groupID > 0) {
            path = `/group/${this.$route.params.groupID}`;
          } else {
            path = this.tuid > 0 ? `/friend/${this.tuid}` : '/groups';
          }
        }
        if (topicID > 0) {
          path = this.$route.params.groupID > 0 ? `/group/${this.$route.params.groupID}/topic/${topicID}` : `/topic/${topicID}`;
        }

        this.$router.push({
          path,
          query: {
            from: this.from,
            tuid: this.tuid,
          },
        });
      } else if (this.$route.params.groupID > 0) {
        const backUrl = this.forward ? this.forward : `/group/${this.$route.params.groupID}`;
        const isMatch = this.$route.matched.find((item) => backUrl.match(item.regex));
        if (isMatch) {
          if (isMatch.name === 'app-group-detail' || isMatch.name === 'app-group-topic-list') {
            this.$router.replace({
              path: '/groups',
            });
          } else {
            // this.$router.go(-1);
            window.history.back();
          }
        } else {
          const finalURL = topicID > 0 ? `/group/${this.$route.params.groupID}/topic/${topicID}` : backUrl;
          this.$router.replace({
            path: finalURL,
          });
        }
      } else if (topicID > 0) {
        this.$router.replace({
          path: `/topic/${topicID}`,
        });
      } else {
        // this.$router.go(this.goBackCount);
        window.history.back();
      }
      console.groupEnd();
    },
    goHome() {
      this.$router.push({
        path: '/',
      });
    },
  },
};

export default shareBack;
