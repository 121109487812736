const longPress = {
  bind(el, binding) {
    if (typeof binding.value !== 'function') {
      console.warn(`Expect a function in directive: 'v-longpress', got ${typeof binding.value}`);
      return;
    }

    let pressTimer = null;

    const cancel = () => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };

    const handler = (e) => {
      binding.value(e);
    };

    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return;
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          handler(e);
        }, 750);
      }
    };

    // 添加事件监听器
    el.addEventListener('mousedown', start);
    el.addEventListener('touchstart', start);
    // 取消计时器的事件
    el.addEventListener('click', cancel);
    el.addEventListener('mouseout', cancel);
    el.addEventListener('touchend', cancel);
    el.addEventListener('touchcancel', cancel);
    el.addEventListener('mouseup', cancel);
  },
};

export default longPress;
