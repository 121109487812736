// import wx from '@/libs/jsweixin-1.6.0';

function preview(e) {
  e.stopPropagation();
  const { src, list } = e.currentTarget.dataset;
  const total = [];
  total.push(...list.split(','));
  window.wx.previewImage({
    current: src,
    urls: total,
  });
}

const imgOnPreview = {
  bind(el) {
    el.addEventListener('click', preview);
  },
  unbind(el) {
    el.removeEventListener('click', preview);
  },
};

export default imgOnPreview;
