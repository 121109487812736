<template>
  <div class="not-found-container">
    <div class="main-body">
      <svg-icon icon-class="icon-circle" class="icon-container"></svg-icon>
      <div class="hint">
        <p v-html="message"></p>
      </div>
      <router-link
        class="button"
        :to="{
          path: url,
        }"
        ><strong>{{ btMsg }}</strong></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'c_not-found',
  props: {
    hint: {
      type: Object,
      default: null,
    },
  },
  computed: {
    message() {
      return this.hint ? this.hint.message : '';
    },
    url() {
      let url = this.hint ? this.hint.url : '/';
      url = `${url}notices?from=notfound`;
      return url;
    },
    btMsg() {
      if (this.hint) {
        return this.hint.url === '/' ? '返回首页' : '查看发布人';
      }
      return '返回首页';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixin";
.not-found-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  padding-top: 200px;
  .main-body {
    width: 100%;
    @include flex-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #bbb;
    height: 100%;
  }
  .icon-container {
    width: 50px;
    height: 50px;
  }
  .hint {
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: auto;
  }
  .button {
    flex: 0 0 98px;
    width: 100%;
    font-size: 32px;
    color: #000;
    line-height: 98px;
    text-align: center;
  }
}
</style>
