<template>
  <div class="page-main-container">
    <div
      class="page-main-body"
      :class="{
        '-without-bottom': $route.meta.hiddenBottom === true,
      }"
    >
      <router-view :key="$route.name" />
    </div>
    <page-menu v-if="!$route.meta.hiddenBottom"></page-menu>
    <not-found v-if="notFound" :hint="notFoundHint"></not-found>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import wxtool from '@/utils/wx-tool';
import notFound from './views/common/_not-found.vue';
import pageMenu from './views/common/_menu.vue';
import { getCookie, getToken } from './libs/token';

const simpleEvent = () => {
  let resolver = null;

  let result = null;

  function addEvent(cb) {
    const cbResult = cb();
    if (resolver) {
      resolver(cbResult);
      result = cbResult;
    }
  }

  function resolveAll() {
    return new Promise((resolve) => {
      if (result === null) {
        resolver = resolve;
      } else {
        resolve(result);
      }
    });
  }

  return {
    addEvent,
    resolveAll,
  };
};

const { addEvent, resolveAll } = simpleEvent();

export default {
  name: 'c_app-main-bone',
  components: {
    pageMenu,
    notFound,
  },
  data() {
    const that = this;
    return {
      timer: null,
      notFound: false,
      notFoundHint: null,
      socketTrigger: {
        'after-group-removed': that.onGroupAfterRemovedInform,
        'new-notice-hint': that.onNoticeNewHint,
        'new-friend-add': that.onNewFriendAdd,
        pong: that.onPong,
      },
      heartbeatTimer: null,
      wss: null,
      retryCount: 0,
      wxInit: 0,
    };
  },
  computed: {
    ...mapState(['token', 'user']),
    wssLink() {
      return this.user
        ? `ws://${this.socketConfig.host}:${this.socketConfig.port}/ws/${this.user.uid}/inform`
        : '';
    },
  },
  watch: {
    $route(val) {
      if (!(val.meta.isNeedLogin === false)) {
        this.loadUserInfo();
      }
      this.notFound = false;

      resolveAll().then(() => {
        this.shareInit();
      });
    },
    user(val) {
      const that = this;

      addEvent(() => val);

      console.log(getCookie(that.$constant.firstInUrlKey));

      // const whitelists = ['app-fill-invite-code', 'app-group-topic-list'];
      // const unActivedLink = '/fill-code';
      // if (!val.actived && whitelists.indexOf(that.$route.name) === -1) {
      //   const firstInURL = getCookie(that.$constant.firstInUrlKey) || undefined;
      //   that.$router.replace({
      //     path: firstInURL || unActivedLink,
      //     query: {
      //       sign: 'appmain',
      //     },
      //   });
      //   return;
      // }

      that.wssInit();
      // that.shareInit();
    },
  },
  mounted() {
    this.$root.$on('forbidden', (info) => {
      if (info) {
        this.$dialog
          .alert({
            title: '消息提示',
            message: info.message,
            confirmButtonText: info.btText,
          })
          .then(() => {
            if (info.confirmUrl) {
              this.$router.replace({
                path: info.confirmUrl,
              });
            }
          });
      }
    });

    this.$root.$on('notfound', (hint) => {
      this.notFound = true;
      this.notFoundHint = hint;
    });
  },
  methods: {
    ...mapActions(['userinfo', 'friends']),
    ...mapMutations(['HAS_UNREAD', 'ADD_TEMP_NEW_FRIEND', 'UPDATE_REMOVE_GROUP']),
    async loadUserInfo() {
      if (this.token && this.user === null) {
        try {
          await this.userinfo();
          await this.friends();
        } catch (e) {
          this.$router.replace({
            path: '/login',
            query: {
              to: encodeURI(this.$route.fullPath),
            },
          });
        }
      }
    },
    checkUnread() {
      const that = this;
      that.$request.get('api/user/notice/check').then((res) => {
        that.HAS_UNREAD(res.data);
      });
    },
    shareInit() {
      // 统一分享
      if (this.user) {
        const title = `${this.user.nickname} 邀请你加入「时光」`;
        const desc = '建设你 建设我 建设我们共同的时光';
        const link = `${process.env.VUE_APP_BASE}friend/${this.user.uid}`;
        const imgUrl = this.user.headimgurl;
        wxtool.share(
          {
            title,
            desc,
            link,
            imgUrl,
          },
          this.$route.fullPath,
        );
      }
    },
    wssInit() {
      if (process.env.NODE_ENV === 'production') {
        this.socketConnect();
      }
      if (this.wss) {
        this.wss.onmessage = this.wsOnMessage;
        this.wss.onopen = this.wsOnOpen;
        this.wss.onerror = this.wsOnError;
        this.wss.onclose = this.wsOnClose;
      }
    },
    wsOnMessage(e) {
      const that = this;
      const data = JSON.parse(e.data);
      if (data.event) {
        that.socketTrigger[data.event](data);
      } else if (data.code && data.code === 403 && this.heartbeatTimer) {
        clearTimeout(this.heartbeatTimer);
      }
    },
    wsOnOpen() {
      if (this.wss.readyState === 1) {
        console.log('wss has been connected');
        this.heartbeatTimer = setTimeout(() => {
          this.heartbeat();
        }, 5000);
      }
    },
    wsOnError(err) {
      console.log('error');
      console.log(err);
      this.reconnect();
    },
    wsOnClose() {
      console.log('close');
      console.log(this.wss.readyState);
      // 可能要重连
      this.reconnect();
    },
    heartbeat() {
      if (this.heartbeatTimer) {
        clearTimeout(this.heartbeatTimer);
      }
      if (this.wss.readyState === 1) {
        this.wss.send('ping');
      } else {
        // 断开
        console.log('断开');
        if (this.wss) {
          this.reconnect();
        }
      }
    },
    onGroupAfterRemovedInform(data) {
      console.log('收到被移除的通知');
      // 更新store
      this.UPDATE_REMOVE_GROUP([data.data]);
    },
    onNoticeNewHint() {
      console.log('新消息提醒');
      const that = this;
      that.HAS_UNREAD(1);
    },
    onNewFriendAdd(message) {
      console.log('对方同意好友申请');
      // 更新store
      this.ADD_TEMP_NEW_FRIEND(message.data);
    },
    onPong() {
      this.heartbeatTimer = setTimeout(() => {
        this.heartbeat();
      }, 10000);
    },
    socketConnect() {
      try {
        const token = getToken();
        this.wss = this.wssLink ? new WebSocket(this.wssLink, [token]) : null;
      } catch (e) {
        this.wss = null;
      }
    },
    reconnect() {
      const that = this;
      that.retryCount += 1;
      if (that.retryCount < 10) {
        clearTimeout(that.heartbeatTimer);
        setTimeout(() => {
          that.wssInit();
        }, 1000 + that.retryCount * 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
