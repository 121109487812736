import Vue from 'vue';
import * as filters from '@/filters';
import localStorage from '@/libs/localStorage';
import bowser from 'bowser';
import baseFn from '@/utils/base';
import request from '@/api/request';
import directives from '@/directives';
import vueTouch from 'vue-touch';
import queryBack from '@/mixins/share-back';
// import VConsole from 'vconsole';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/scss/style.scss';

// import '@/plugins/element';
import '@/plugins/vant';
import '@/plugins/wxjssdk';
import '@/plugins/icons';

Vue.config.productionTip = process.env.NODE_ENV === 'development';

Vue.prototype.$localStorage = localStorage;
Vue.prototype.$bowser = bowser;
Vue.prototype.$screenHeight = window.innerHeight;
Vue.prototype.$request = request;

// Vue.prototype.$vConsole = new VConsole();

const host = process.env.VUE_APP_BASE;

Vue.prototype.socketConfig = {
  host: process.env.VUE_APP_WEBSOCKET_URL,
  port: 3086,
};

Vue.prototype.officialAccount = {
  appid: process.env.VUE_APP_WX_APPID,
  secret: process.env.VUE_APP_WX_SECRET,
  redirectURI: `${host}login/redirect`,
};

Vue.prototype.$constant = {
  unActiveWhiteList: ['app-fill-invite-code', 'app-group-topic-list'],
  firstInUrlKey: 'firstInURL',
};

Vue.prototype.defaultBg = 'http://timeline-file.crosscrew.cc/static/notice-default.png';
Vue.prototype.weeklyDefaultPic = 'http://timeline-file.crosscrew.cc/static/weekly-head.png';
Vue.prototype.imgHost = 'http://timeline-file.crosscrew.cc/';

Vue.prototype.userJump = (uid) => {
  if (uid > 0) {
    router.push({
      path: `/friend/${uid}`,
    });
  }
};

Vue.prototype.generateAfterCreatePostLink = (link) => {
  store.commit('SET_AFTER_CRAETEPOST_LINK', link);
};

Vue.prototype.viewTopicPosts = (topicid, postids, uid) => {
  const url = 'api/user/topic/viewed';
  request.post(url, {
    topicid, postids, uid,
  }).then((res) => {
    console.log(res);
  });
};

Vue.mixin(queryBack);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(directives);

Vue.use(baseFn);

Vue.use(vueTouch);

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
});
vm.$mount('#app');
