import imgPreview from './imgPreview';
import countdown from './countdown';
import longPress from './longPress';

const directives = {
  preview: imgPreview,
  countdown,
  longPress,
};

export default {
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key]);
    });
  },
};
