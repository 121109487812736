import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { getToken, getCookie } from '@/libs/token';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/home/login.vue'),
    meta: {
      isNeedLogin: false,
      hiddenBottom: true,
    },
  },
  {
    path: '/test',
    name: 'home',
    component: () => import('../views/home/test.vue'),
    meta: {
      isNeedLogin: false,
    },
  },
  {
    path: '/',
    name: 'app-notice-list',
    // redirect: '/notices',
    component: () => import('../views/notice/index.vue'),
  },
  // 消息列表
  // {
  //   path: '/notices',
  //   name: 'app-notice-list',
  //   component: () => import('../views/notice/index.vue'),
  // },
  {
    path: '/preview',
    name: 'user-preview',
    component: () => import('../views/friends/detail.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 亲友列表(含亲友和小组列表)
  // {
  //   path: '/part',
  //   name: 'nav-friends',
  //   component: () => import('../views/bottom-nav/nav-friend.vue'),
  //   redirect: '/part/friends',
  //   children: [
  //     {
  //       path: 'friends',
  //       name: 'app-friends-list',
  //       component: () => import('../views/friends'),
  //     },
  //     {
  //       path: 'groups',
  //       name: 'app-group-list',
  //       component: () => import('../views/group'),
  //     },
  //   ],
  // },
  // 好友列表
  {
    path: '/friends',
    name: 'route-friends',
    component: () => import('../views/friends/index.vue'),
  },
  // 小组列表
  {
    path: '/groups',
    name: 'route-groups',
    component: () => import('../views/group/index.vue'),
  },
  {
    path: '/notice/:groupID/group',
    name: 'router-notice-group-detail',
    component: () => import('../views/notice/index.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  {
    path: '/notice/:weekly/weekly',
    name: 'router-notice-weekly-list',
    component: () => import('../views/notice/index.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 周报详情
  {
    path: '/notice/weekly-report',
    name: 'app-sys-weekly-report',
    component: () => import('../views/notice/sys.vue'),
    meta: {
      hiddenBottom: true,
    }
    ,
  },
  // 分享列表
  {
    path: '/topics',
    name: 'app-topic-list',
    component: () => import('../views/topic/index.vue'),
  },
  // 我的
  {
    path: '/home',
    name: 'app-user-home',
    component: () => import('../views/user/index.vue'),
  },
  // 好友列表
  {
    path: '/friend/:friendUid',
    name: 'app-friend-detail',
    component: () => import('../views/friends/detail.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 好友设置
  {
    path: '/friend/:id/setup',
    name: 'app-friend-set-up',
    component: () => import('../views/friends/setup.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 好友时间轴
  {
    path: '/friend/:friendUid/timeline',
    name: 'app-friend-timeline-list',
    component: () => import('../views/friends/timeline.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 好友分享主题
  {
    path: '/friend/:id/topics/:topicid',
    name: 'app-friend-topic-list',
    component: () => import('../views/topic/detail.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 创建主题
  {
    path: '/topic/edit/:topicid?',
    name: 'app-topic-edit',
    component: () => import('../views/topic/edit.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 主题详情
  {
    path: '/topic/:topicid',
    name: 'app-topic-detail',
    component: () => import('../views/topic/detail.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 创建主题帖子
  {
    path: '/topic/post/edit/:topicid?',
    name: 'app-topic-post-edit',
    component: () => import('../views/topic/post-edit.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 主题帖子详情
  {
    path: '/topic/post/:postid',
    name: 'app-topic-post-detail',
    component: () => import('../views/topic/post-detail.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
      deletedBack: true,
      withoutForwardBack: true,
    },
  },
  // 创建新group
  {
    path: '/group/create',
    name: 'app-group-create',
    component: () => import('../views/group/create.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  {
    path: '/group/edit/:id',
    name: 'app-group-edit',
    props: true,
    component: () => import('../views/group/create.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 小组时光
  {
    path: '/group/:groupID/timelines',
    name: 'route-group-timeline',
    component: () => import('../views/friends/timeline.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 小组时光详情
  {
    path: '/group/:groupID/timeline/:timelineid',
    name: 'route-group-timeline-detail',
    component: () => import('../views/friends/timeline.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // 小组主题帖子详情
  {
    path: '/group/:groupID/topic/post/:postid',
    name: 'router-group-topic-post-detail',
    component: () => import('../views/topic/post-detail.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
      deletedBack: true,
      withoutForwardBack: true,
    },
  },
  // 小组加入请求
  {
    path: '/group/:groupID/apply',
    name: 'route-group-apply',
    component: () => import('../views/group/apply.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // group 详情
  {
    path: '/group/:groupID',
    name: 'app-group-detail',
    component: () => import('../views/group/detail.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
    redirect: '/group/:groupID/topics',
    children: [
      {
        path: 'posts',
        name: 'app-group-detail-posts-list',
        component: () => import('../views/group/posts.vue'),
        props: true,
        meta: {
          hiddenBottom: true,
        },
      },
      {
        path: 'topics',
        name: 'app-group-topic-list',
        component: () => import('../views/group/topics.vue'),
        props: true,
        meta: {
          hiddenBottom: true,
        },
      },
    ],
  },
  // group 成员
  {
    path: '/group/:groupID/members',
    name: 'app-group-detail-member-list',
    prop: true,
    component: () => import('../views/group/members.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // group topic list
  {
    path: '/group/:groupID/topic/:topicid',
    props: true,
    name: 'app-group-topic-detail',
    // component: () => import('../views/group/topic.vue'),
    component: () => import('../views/topic/detail.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // group topic 设置
  {
    path: '/group/:groupID/topic/:topicid/edit',
    name: 'app-topic-edit-group',
    component: () => import('../views/topic/edit.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // group 设置管理员
  {
    path: '/group/:groupid/manager',
    name: 'app-group-managers',
    component: () => import('../views/group/manager.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // group某条帖子详情
  {
    path: '/group/:groupid/post/:postid',
    name: 'app-group-post-detail',
    component: () => import('../views/group/post.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // group新建帖子
  {
    path: '/group/:id/edit/:postid?',
    name: 'app-group-post-edit',
    component: () => import('../views/group/post-edit.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 用户搜索
  {
    path: '/users/search',
    name: 'app-membe-search',
    component: () => import('../views/user/search.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 我的申请管理
  {
    path: '/my/apply-manage',
    name: 'app-friend-apply',
    component: () => import('../views/apply/index.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 我的设置
  {
    path: '/my/setup',
    name: 'app-my-config-setup',
    component: () => import('../views/user/setup.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  {
    path: '/my/timeline',
    name: 'app-my-timeline',
    component: () => import('../views/friends/timeline.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 邀请码
  {
    path: '/my/code',
    name: 'app-my-invite-code',
    component: () => import('../views/invite/list.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 输入邀请码
  {
    path: '/fill-code/:code?',
    name: 'app-fill-invite-code',
    component: () => import('../views/invite/index.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  // {
  //   path: '/do-it-self',
  //   name: 'app-test',
  //   component: () => import('../views/home/happy.vue'),
  //   meta: {
  //     isNeedLogin: false,
  //     hiddenBottom: true,
  //   },
  // },
  // 时光编辑
  {
    path: '/timeline/edit/:id?',
    name: 'app-timeline-edit',
    component: () => import('../views/timeline/edit.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 时光详情
  {
    path: '/timeline/:timelineid',
    name: 'app-timeline-post-detail',
    component: () => import('../views/friends/timeline.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
    },
  },
  {
    path: '/instructions',
    name: 'app-instructions4app',
    component: () => import('../views/instructions/index.vue'),
    meta: {
      hiddenBottom: true,
      isNeedLogin: false,
    },
  },
  {
    path: '/instructions/:type(briefs|explain|private|protocol)',
    name: 'app-instructions4deail',
    component: () => import('../views/instructions/rich-text.vue'),
    props: true,
    meta: {
      hiddenBottom: true,
      isNeedLogin: false,
    },
  },
  {
    path: '/instructions/feedback',
    name: 'app-feedback',
    component: () => import('../views/instructions/feedback.vue'),
    meta: {
      hiddenBottom: true,
    },
  },
  // 时光帖子详情
  // {
  //   path: '/timeline/post/:postid',
  //   name: 'app-timeline-post-detail',
  //   component: () => import('../views/timeline/post-detail.vue'),
  //   props: true,
  //   meta: {
  //     hiddenBottom: true,
  //   },
  // },
  // 微信授权回调
  {
    path: '/login/redirect',
    name: 'app-wx-redirect',
    component: () => import('../views/home/login.vue'),
    meta: {
      hiddenBottom: true,
      isNeedLogin: false,
    },
  },
];

// eslint-disable-next-line max-len
const STORED_URLS = ['app-notice-list', 'app-friends-list', 'route-groups', 'app-topic-list', 'app-user-home', 'app-group-topic-list', 'router-notice-group-detail', 'app-friend-timeline-list', 'route-group-timeline', 'app-group-topic-detail'];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    return {
      x: 0,
      y: 0,
    };
  },
});

// 没有token 直接 重定向到登录 ，记录本来要去的url
// 有token，获取store 用户信息，判断用户是否激活，若没有激活去invite页面
router.beforeEach((to, from, next) => {
  if (to.meta.isNeedLogin === false) {
    next();
  } else {
    // 需要登录
    // 先判断是否有token
    const token = getToken();
    // 如果没有token 重定到 login 并且记录本来要去页面
    if (!token) {
      next({
        path: '/login',
        query: {
          to: encodeURI(to.fullPath),
        },
      });
      return;
    }

    // 登录之后用户信息记录到store
    // 获取user store信息
    // 用户是否激活
    const { user } = store.state;
    const unActivedWhiteLists = ['app-fill-invite-code', 'app-group-topic-list'];
    // 没有激活并且不在未激活域名白名单
    if (user && !user.actived && unActivedWhiteLists.indexOf(to.name) === -1) {
      const go = getCookie('firstInURL');
      // 未激活的情况下
      next({
        path: go || '/fill-code',
        query: {
          redirect: 'unactiveAfterGone',
          sign: 'routemap',
        },
      });
      return;
    }
    if (to.name === 'app-friend-detail') {
      const { friendUid } = to.params;
      const queryFrom = to.query.from;
      const p = new Promise((resolve) => {
        if (store.state.user) {
          resolve(store.state.user);
        } else {
          store.dispatch('userinfo').then((res) => {
            resolve(res);
          });
        }
      });
      p.then((info) => {
        if (Number(friendUid) === Number(info.uid)) {
          router.push({
            path: queryFrom ? `/preview?from=${queryFrom}` : '/preview',
          });
        } else {
          next();
        }
      });
    } else {
      next();
    }
  }

  // const inviteUrl = getCookie('invite_url');
  // if (to.name === 'app-fill-invite-code' && !inviteUrl) {
  //   setCookie('invite_url', to.path);
  // }
});

const clearGoBackCountsRoute = [
  'app-notice-list',
  'route-groups',
  'route-friends',
  'app-topic-list',
  'app-user-home',
];

router.afterEach((to, from) => {
  if (clearGoBackCountsRoute.includes(to.name)) {
    store.commit('setCurrentBackCount', true);
  }

  if (!store.state.wxLink) {
    store.commit('SET_WX_LINK', window.location.href);
  }
  if (STORED_URLS.findIndex((item) => item === from.name) >= 0) {
    store.commit('setForward', from.fullPath);
  }
  // store.commit('setForward', STORED_URLS.findIndex((item) => item === from.name) >= 0 ? from.fullPath : null);
  // console.warn('aftereach begin');
  // console.warn('aftereach end');
});

export default router;
