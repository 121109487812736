function fill0(n) {
  return n < 10 ? `0${n}` : n;
}
function parseTime(time) {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time - h * 3600) / 60);
  const s = time - h * 3600 - m * 60;
  return `${fill0(h)}:${fill0(m)}:${fill0(s)}`;
}

const timer = {
  lasts: 0,
  loop: null,
  wrapper: null,
  init(endAt) {
    if (this.loop) {
      this.destory();
    }
    if (endAt > 0) {
      const startAt = Math.floor(Date.now() / 1000);
      this.lasts = endAt - startAt;
      if (this.lasts > 0) {
        this.start();
      } else {
        this.wrapper.innerHTML = '00:00:00';
      }
    }
  },
  start() {
    const that = this;
    let { lasts } = that;
    that.wrapper.innerHTML = `已申请 ${parseTime(lasts)}`;
    that.loop = setInterval(() => {
      lasts -= 1;
      that.wrapper.innerHTML = `已申请 ${parseTime(lasts)}`;
    }, 1000);
  },
  destory() {
    clearInterval(this.loop);
  },
};
const countdown = {
  bind(el, { value }) {
    const wrapper = el;
    timer.wrapper = wrapper;
    if (value > 0) {
      timer.init(value);
    }
  },
  update(el, { value }) {
    if (value > 0) {
      timer.init(value);
    }
  },
  unbind() {
    timer.destory();
  },
};

export default countdown;
