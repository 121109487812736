// import wx from '@/libs/jsweixin-1.6.0';
import request from '@/api/request';
// import store from '@/store';
// import bowser from 'bowser';

// const _vConsole = new window.VConsole();
// console.log(_vConsole);

export default {
  // eslint-disable-next-line no-unused-vars
  init(paramUrl) {
    // eslint-disable-next-line no-nested-ternary
    // const url = bowser.ios ? store.state.wxLink : (store.state.wxLink ? store.state.wxLink : window.location.href.split('#')[0]);
    const url = window.location.href;
    return new Promise((resolve, reject) => {
      request
        .get('/api/default/signature', {
          url: encodeURIComponent(url),
        })
        .then((res) => {
          const { data } = res;
          window.wx.config({
            // debug: process.env.NODE_ENV === 'development',
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            signature: data.signature,
            jsApiList: data.jsApiList,
          });
          window.wx.ready(() => {
            resolve(window.wx);
          });
          window.wx.error((e) => {
            console.log(`wx error:${JSON.stringify(e)}`);
          });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  share(shareData, currentPath) {
    const {
      title, desc, link, imgUrl,
      tuid,
    } = shareData;
    const url = tuid ? `${link}?from=wechat&tuid=${tuid}` : `${link}?from=wechat`;
    this.init(currentPath).then((wxSDK) => {
      wxSDK.updateAppMessageShareData({
        title,
        desc,
        link: url,
        imgUrl,
        success() {
        },
      });
      wxSDK.updateTimelineShareData({
        title,
        desc,
        link: url,
        imgUrl,
        success() {
          // console.info('分享成功');
        },
      });
    }).catch((err) => {
      console.log(`init error: ${JSON.stringify(err)
      }`);
    });
  },
  pay(config) {
    this.init().then((wxSDK) => {
      wxSDK.chooseWXPay(config);
    });
  },
};
