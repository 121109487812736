import Vue from 'vue';
import Vuex from 'vuex';
import request from '@/api/request';
import { getToken, setToken } from '@/libs/token';
import localStorage from '@/libs/localStorage';

Vue.use(Vuex);

const WeeklyNoticeLK = 'flashback:weekly:notice';

function clearRemoveInfo(cacheKEY, conditionKEY, removeID) {
  let dataset = localStorage.get(cacheKEY) || [];
  if (dataset.length > 0) {
    dataset = dataset.filter((data) => Number(data[conditionKEY]) !== Number(removeID));
  }
  return dataset;
}

export default new Vuex.Store({
  state: {
    forward: null,
    wxLink: null,
    user: null,
    token: getToken(),
    isShowReplyForm: false,
    userGroupBaseInfo: {},
    friends: null,
    unreadCount: 0,
    unread: false,
    userTopics: null,
    friendsPositionInfo: null,
    inviteUrl: '',
    tempCreateTopicPost: null,
    myGroups: new Map(),
    afterCreatePostLink: '',
    localGroupMenus: [],
    localRemoveGroups: [],
    groupNoticeUnread: 0,
    weeklyNotice: {
      userDelete: false,
      userRead: false,
      latest: {
        createdAt: 0,
        title: '',
      },
    },
    goBackCount: 0,
  },
  mutations: {
    setCurrentBackCount(state, clear = false) {
      if (clear === true) {
        state.goBackCount = 0;
      } else {
        state.goBackCount -= 1;
      }
    },
    getWeeklyNotice(state) {
      // return localStorage.has(WeeklyNoticeLK) ? localStorage.get(WeeklyNoticeLK) : state.weeklyNotice;
      if (localStorage.has(WeeklyNoticeLK)) {
        state.weeklyNotice = localStorage.get(WeeklyNoticeLK);
      }
    },
    setWN(state, data) {
      state.weeklyNotice = data;
      localStorage.set(WeeklyNoticeLK, data);
    },
    clearRemovedGroup(state, groupID) {
      const ckey = 'local:removed:group';
      const data = clearRemoveInfo(ckey, 'id', groupID);
      localStorage.set(ckey, data);
      state.localRemoveGroups = data;
    },
    UPDATE_REMOVE_GROUP(state, removeGroup) {
      const ckey = 'local:removed:group';
      let local = localStorage.get(ckey) || [];
      if (removeGroup.length > 0) {
        if (local.length === 0) {
          local = removeGroup;
        } else {
          // const tempGroup = local.concat(removeGroup);
          removeGroup.forEach((group) => {
            if (local.find((item) => Number(item.id) !== Number(group.id))) {
              local.push(group);
            }
          });
        }
      }
      localStorage.set(ckey, local);
      state.localRemoveGroups = local;
    },
    REMOVE_GROUP_MENU(state, groupID) {
      const ckey = 'local:group:menus';
      const data = clearRemoveInfo(ckey, 'groupID', groupID);
      localStorage.set(ckey, data);
      state.localRemoveGroups = data;
    },
    UPDATE_GROUP_MENU(state, menu) {
      const ckey = 'local:group:menus';
      let local = localStorage.get(ckey) || [];
      const handlerGroupID = [];
      const manualDel = [];
      if (local.length === 0 && menu) {
        local = menu.filter((item) => {
          if (!(item.cover && item.title)) {
            handlerGroupID.push(item.groupID);
          }
          return item.cover && item.title;
        });
        if (handlerGroupID.length > 0) {
          request.post('api/group/remove-notice/', {
            handlerGroupID,
          });
        }
      } else {
        const formatTimestamp = {};
        const temp = local.concat(menu).filter((item) => {
          let sign = false;
          if (!(item.cover && item.title)) {
            handlerGroupID.push(Number(item.groupID));
          }
          if (menu.findIndex((f) => f.groupID === item.groupID) === -1) {
            manualDel.push(Number(item.groupID));
            sign = true;
          }
          if (undefined === formatTimestamp[item.groupID]) {
            formatTimestamp[item.groupID] = {};
            formatTimestamp[item.groupID].currentLastestAt = item.currentLastestAt;
          } else if (item.currentLastestAt > formatTimestamp[item.groupID].currentLastestAt) {
            formatTimestamp[item.groupID].currentLastestAt = item.currentLastestAt;
          }
          formatTimestamp[item.groupID].hasUnRead = false || item.hasUnRead;
          return item.cover && item.title && !sign;
        }).map((item) => {
          const tempInfo = item;
          tempInfo.currentLastestAt = formatTimestamp[item.groupID].currentLastestAt;
          tempInfo.isDissolve = handlerGroupID.indexOf(Number(item.groupID)) >= 0;
          tempInfo.manualDel = manualDel.indexOf(Number(item.groupID)) >= 0;
          tempInfo.hasUnRead = tempInfo.isDissolve || tempInfo.manualDel ? false : formatTimestamp[item.groupID].hasUnRead;
          return JSON.stringify(tempInfo);
        });
        local = [...new Set([...temp])].map((item) => JSON.parse(item));
      }
      localStorage.set(ckey, local);
      state.localGroupMenus = local;
    },
    SET_AFTER_CRAETEPOST_LINK(state, link) {
      state.afterCreatePostLink = link;
    },
    REMOVE_TEMP_TOPIC_POST(state) {
      state.tempCreateTopicPost = null;
    },
    SET_TEMP_TOPIC_POST(state, data) {
      state.tempCreateTopicPost = data;
    },
    SET_INVITE_URL(state, url) {
      state.inviteUrl = url;
    },
    setForward(state, data) {
      state.forward = data;
    },
    SET_USER_ACTIVED(state) {
      if (state.user) {
        state.user.actived = true;
      }
    },
    UPDATE_USER(state, data) {
      state.user[data.key] = data.val;
    },
    HAS_UNREAD(state, data) {
      state.unread = data > 0;
    },
    SET_WX_LINK(state, data) {
      state.wxLink = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_USER_INFO(state, data) {
      state.user = data.user;
      state.friends = data.friends;
    },
    OPEN_REPLY_FORM(state) {
      state.isShowReplyForm = true;
    },
    CLOSE_REPLY_FORM(state) {
      state.isShowReplyForm = false;
    },
    // 设置小组帖子解锁数和总数
    SET_GROUP_COUNTS(state, data) {
      this._vm.$set(state.userGroupBaseInfo, data.groupid, {});
      this._vm.$set(state.userGroupBaseInfo[data.groupid], 'currentCount', data.currentCount);
      this._vm.$set(state.userGroupBaseInfo[data.groupid], 'totalCount', data.totalCount);
    },
    // 增加小组帖子解锁数
    ADD_GROUP_POST_CLEAR_COUNT(state, groupid) {
      let num = Number(state.userGroupBaseInfo[groupid].currentCount);
      if (state.userGroupBaseInfo[groupid]) {
        num += 1;
      } else {
        num = 1;
      }
      // state.userGroupBaseInfo[groupid].currentCount = num;
      this._vm.$set(state.userGroupBaseInfo[groupid], 'currentCount', num);
    },
    // 减少小组帖子解锁数
    SUBTRACT_GROUP_POST_CLEAR_COUNT(state, groupid) {
      let num = Number(state.userGroupBaseInfo[groupid].currentCount);
      if (state.userGroupBaseInfo[groupid]) {
        num -= 1;
      } else {
        num = 0;
      }
      // state.userGroupBaseInfo[groupid].currentCount = num;
      this._vm.$set(state.userGroupBaseInfo[groupid], 'currentCount', num);
    },
    // 增加小组帖子总数
    ADD_GROUP_POST_TOTAL_COUNT(state, groupid) {
      let num = Number(state.userGroupBaseInfo[groupid].totalCount);
      if (state.userGroupBaseInfo[groupid]) {
        num += 1;
      } else {
        num = 1;
      }
      // state.userGroupBaseInfo[groupid].totalCount = num;
      this._vm.$set(state.userGroupBaseInfo[groupid], 'totalCount', num);
    },
    // 减少小组帖子总数
    SUBTRACT_GROUP_POST_TOTAL_COUNT(state, groupid) {
      let num = Number(state.userGroupBaseInfo[groupid].totalCount);
      if (state.userGroupBaseInfo[groupid]) {
        num -= 1;
      } else {
        num = 0;
      }
      // state.userGroupBaseInfo[groupid].totalCount = num;
      this._vm.$set(state.userGroupBaseInfo[groupid], 'totalCount', num);
    },
    SET_FRIENDS_POS_INFO(state, info) {
      let lastQueue = state.friendsPositionInfo ? state.friendsPositionInfo.length : 1;
      const temp = state.friendsPositionInfo ? state.friendsPositionInfo : [];
      if (state.friends) {
        info.forEach((item) => {
          const currentFriend = state.friends.find((user) => Number(user.info.uid) === Number(item.info.uid));
          temp.push({
            uid: item.info.uid,
            position: lastQueue,
            nickname: currentFriend.comment ? currentFriend.comment : currentFriend.info.nickname,
          });
          lastQueue += 1;
        });
      }
      state.friendsPositionInfo = temp;
    },
    UPDATE_FRIEND(state, info) {
      const data = state.friends.map((item) => {
        const temp = item;
        if (Number(item.info.uid) === Number(info.uid)) {
          temp.comment = info.comment;
        }
        return temp;
      });
      state.friends = data;
    },
    ADD_TEMP_NEW_FRIEND(state, friend) {
      if (!state.friends.find((item) => Number(item.info.uid) === Number(friend.uid))) {
        state.friends.push({
          comment: friend.comment,
          info: friend.info,
          user_relation: friend.user_relation,
          temp: true,
        });
      }
    },
    UPDATE_MYGROUPS_INFO(state, group) {
      state.myGroups.set(Number(group.id), group);
    },
  },
  actions: {
    login({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('officialAccount', {
          code: data.code,
        })
          .then((res) => {
            const {
              jwt, user, friends, actived, newly,
            } = res.data;
            user.actived = actived;
            user.newly = newly;
            setToken(jwt, 7);
            commit('SET_TOKEN', jwt);
            commit('SET_USER_INFO', {
              user,
              friends,
            });
            resolve(res);
          }).catch((err) => {
            reject(err.message);
          });
      });
    },
    officialAccount(...args) {
      const data = args[1];
      return new Promise((resolve, reject) => {
        request
          .get('api/login', {
            code: data.code,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    userinfo({ commit }) {
      return new Promise((resolve, reject) => {
        request.get('api/user/account')
          .then((res) => {
            const { data, status } = res;

            if (status === 401 || data === null) {
              // token 过期
              // 重新登录
              reject(new Error('need login'));
            } else {
              const {
                user, friends, actived, newly,
              } = data;
              user.actived = actived;
              user.newly = newly;
              commit('SET_USER_INFO', {
                user,
                friends,
              });
              resolve(user);
            }
          }).catch((err) => {
            console.log(err);
            reject(new Error('need login'));
            // window.location.href = '/login';
          });
      });
    },
    friends({ commit }) {
      return new Promise((resolve) => {
        request.get('api/user/friend', {
          count: true,
        }).then((res) => {
          const lists = res.data;
          if (lists) {
            if (lists.length > 0) {
              commit('SET_FRIENDS_POS_INFO', lists);
            }
          }
          resolve(lists);
        });
      });
    },
    getGroup({ state, commit }, groupID) {
      return new Promise((resolve) => {
        const storeGroup = state.myGroups.get(Number(groupID));
        if (storeGroup === undefined) {
          request.get(`api/group/${groupID}`).then((res) => {
            const { data } = res;
            const storeData = {
              totalTimelineCount: data.totalTimelineCount,
              ...data.info,
            };
            commit('UPDATE_MYGROUPS_INFO', storeData);
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
  },
  getters: {
    postTotalCount: (state) => (groupid) => (state.userGroupBaseInfo[groupid] ? state.userGroupBaseInfo[groupid].totalCount : 0),
    postClearedCount: (state) => (groupid) => (state.userGroupBaseInfo[groupid] ? state.userGroupBaseInfo[groupid].currentCount : 0),
    friendPosition: (state) => (uid) => {
      if (state.friendsPositionInfo) {
        return state.friendsPositionInfo.find((user) => Number(user.uid) === Number(uid));
      }
      return null;
    },
  },
  modules: {
  },
});
