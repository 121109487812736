<template>
  <div class="page-bottom-container">
    <div class="bottom-container__inner">
      <div class="bottom__item" v-for="(nav, index) in navs" :key="index" :class="{
        '-has-unread': unread && nav.name === '消息',
      }">
        <template v-if="nav.name === '消息'">
          <div class="item__main-body" @click="noticeJump(unread, nav.link)" :class="{
            'router-link-active': $route.name === 'app-notice-list',
          }">
            <i><svg-icon :icon-class="nav.icon"></svg-icon></i>
            <p>{{ nav.name }}</p>
          </div>
        </template>
        <router-link v-else class="item__main-body" :to="{
          path: nav.link,
        }" :class="{
  'router-link-active': nav.name === '亲友' && currentURI === 'route-groups'
}">
          <i><svg-icon :icon-class="nav.icon"></svg-icon></i>
          <p>{{ nav.name }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'app-bottom-nav',
  data() {
    return {
      navs: [
        // {
        //   name: '测试',
        //   icon: 'nav-notice',
        //   link: '/test',
        // },
        {
          name: '消息',
          icon: 'nav-notice',
          link: '/',
        },
        {
          name: '亲友',
          icon: 'nav-users',
          link: '/friends',
        },
        {
          name: '分享',
          icon: 'nav-share',
          link: '/topics',
        },
        // {
        //   name: '小组',
        //   icon: 'icon-group',
        //   link: '/groups',
        // },
        {
          name: '我',
          icon: 'nav-user',
          link: '/home',
        },
      ],
      currentIndex: 0,
    };
  },
  computed: {
    ...mapState(['unread']),
    currentURI() {
      return this.$route.name;
    },
  },
  methods: {
    noticeJump(isUnread, path) {
      const that = this;
      if (that.$route.name !== 'app-notice-list') {
        that.$router.push({
          path,
        });
      } else if (isUnread) {
        const $unread = [...document.querySelectorAll('.unread')];
        if ($unread.length > 0) {
          const $currentShow = $unread[that.currentIndex];
          const offsetTop = document.documentElement.scrollTop + $currentShow.getBoundingClientRect().top;
          window.scrollTo({
            top: offsetTop,
          });
          this.currentIndex = this.currentIndex === $unread.length - 1 ? 0 : this.currentIndex + 1;
        }
      }
    },
  },
};
</script>

<style></style>
